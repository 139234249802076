.App {
  text-align: center;
}

body {
  /* background-color: #141f25; */
}

.modal-content {
  background-color: #3f4850;
  border-radius: 15px; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  margin: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin); */
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
.in-app::-webkit-input-placeholder {
  color: "#fff";
  -webkit-appearance: none;
} */

input:focus, .updateUser:focus {
  border-color: transparent;
  outline: none;
}


.in-app:focus {
    background-color: #3a4863;
    border: 1px solid #3a4863;
    box-shadow: 5px 5px 5px 5px #3a4863;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  margin-top: 15px;
  width: 40px;
  height: 40px;
  border: 15px solid #dd6508; /* #d2d4d8;  #f3f3f3; */ /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

circle {
  fill: #141f25;
  stroke: url(#gradient);
  stroke-width: 3;
  stroke-dasharray: 176;
  stroke-dashoffset: 176;
  animation: anim 2s linear forwards;
  /* transform: rotate(-90deg); */
}

circle.idea {
  fill: transparent;
  stroke: url(#gradient);
  stroke-width: 8;
  stroke-dasharray: 289;
  stroke-dashoffset: 289;
  animation: anim2 2s linear forwards;
  /* transform: rotate(90deg); */
}

@keyframes anim2 {
  100% {
    stroke-dasharray: 289;
  }
}

@keyframes anim {
  100% {
    stroke-dasharray: 176;
  }
}


@keyframes confetti-slow {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg);
  }
}
@keyframes confetti-medium {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg);
  }
}
@keyframes confetti-fast {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg);
  }
}
.confetti-container {
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0;
}
.confetti--animation-slow {
  animation: confetti-slow 2.25s linear 1 forwards;
}
.confetti--animation-medium {
  animation: confetti-medium 1.75s linear 1 forwards;
}
.confetti--animation-fast {
  animation: confetti-fast 1.25s linear 1 forwards;
}

body::-webkit-scrollbar, div::-webkit-scrollbar {
  scrollbar-color: #141f25 #141f25;
  
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
body::-webkit-scrollbar-track, div::-webkit-scrollbar-track, ::-webkit-scrollbar-track {
  background: #141f25 #141f25;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #141f25 #141f25;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #141f25;
}